<template>
<div id="">
  <Navbar></Navbar>

  <v-container>
    <v-layout align-center justify-center >
      <v-flex md6 lg6>

        <form>
          <v-card class="my-margin">
            <v-card-title>
              <div class="title">{{$t("navbar.signup")}}</div>
            </v-card-title>
            <v-card-text>
              <div class="md-layout md-gutter">
                <section :class="{'signup-error':validations.userName}">
                  <v-text-field :label="$t('profile.username')" hide-details type="text" name="userName" id="userName" autocomplete="name" :error-messages="errorMessage.userName" v-model="userName" />
                  <span class="md-helper-text signup-error" v-if="validations.userName">{{errorMessage.userName}}</span>
                </section>
                <section :class="{'signup-error':validations.email}">
                  <v-text-field :label="$t('tenants.email')" type="email" hide-details name="email" id="email" :error-messages="errorMessage.email" autocomplete="email" v-model="email" />
                  <span class="md-helper-text signup-error" v-if="validations.email">{{errorMessage.email}}</span>
                </section>
                <section :class="{'signup-error':validations.password}">
                  <v-text-field :label="$t('profile.password')" hide-details type="password" :error-messages="errorMessage.password" name="password" id="password" autocomplete="password" v-model="password" />
                  <span class="md-helper-text signup-error" v-if="validations.password">{{errorMessage.password}}</span>
                </section>
                <section :class="{'signup-error':validations.confirmPassword}">
                  <v-text-field :label="$t('auth.confirmPassword')" type="password" :error-messages="errorMessage.confirmPassword" hide-details name="confirmPassword" id="confirmPassword" autocomplete="password" v-model="confirmPassword" />
                  <span class="signup-error" v-if="validations.password">{{errorMessage.confirmPassword}}</span>
                </section>
                <div class="errorMessage">
                  <span class="md-helper-text signup-error" v-if="errorMessage!=''">{{errorMessage.general}}</span>
                </div>
                <v-checkbox hide-details class='md-layout-item md-size-100 no-padding' v-model="terms" :class="{'signup-error':validations.terms}" :error-messages="errorMessage.terms">
                      <span slot="label">{{$t('auth.accept')}} <a class="link" href="/policies/terms" target="_blank">{{$t('auth.terms')}}</a></span>
                </v-checkbox>
                <v-checkbox hide-details :label="$t('auth.landlord')" class='md-layout-item md-size-100 no-padding' v-model="landlord"></v-checkbox>

                <v-checkbox hide-details :label="$t('auth.keepLogged')" class='md-layout-item md-size-100 no-padding' v-model="keepLogged"></v-checkbox>

              </div>

            </v-card-text>


            <v-card-actions>
              <v-layout justify-space-between >
                <v-flex class="flex-0">
                  <v-btn href='/login'>{{$t('navbar.login')}}</v-btn>
                </v-flex>
                <v-flex class="flex-0">
                  <v-btn color="primary" id="signUp" :disabled="sending" @click="onSubmit">{{$t('auth.createUser')}}</v-btn>
                </v-flex>
              </v-layout>

            </v-card-actions>
          </v-card>
        </form>

      </v-flex>
    </v-layout>
  </v-container>


</div>
</template>
<script>
import config from '../../config'
import {
  mapActions
} from 'vuex'
import * as types from '../../store/types'
import axios from 'axios'
import axiosAuth from '../../axios-auth'
import AWSSES from '../../aws-ses'
// AWSSES.emailing(userData, 'accountDeleted')


export default {
  data: () => ({
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    landlord: false,
    roleValue: 10,
    keepLogged: false,
    terms: false,
    validations: {
      userName: false,
      email: false,
      password: false,
      confirmPassword: false,
      terms: false,
    },
    errorMessage:{
    },
    sending: false,
  }),
  methods: {
    ...mapActions({
      signUpAction: types.SIGN_UP
    }),
    validate() {
      this.error = false
      this.errorMessage = {}
      this.validations = {
        userName: false,
        email: false,
        password: false,
        confirmPassword: false,
        terms: false,
      }
      if (!this.userName || this.userName == "") {
        this.validations.userName = true
        this.errorMessage.userName = this.$t('auth.usernameErr')
        this.error = true
      }
      if (this.userName!=""){
        let userNameRegex = /^[\x20-\x7E]*$/;
        let validName = userNameRegex.test(String(this.userName).toLowerCase());
        if (validName==false){
          this.validations.userName = true
          this.errorMessage.userName = this.$t('auth.userNameRegexErr')
          this.error = true
        }

      }
      if (this.email != "") {
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let valid = emailRegex.test(String(this.email).toLowerCase());
        if (valid){
          axios.get('/users.json?orderBy="email"&equalTo="' + this.email + '"')
          .then(res => {
            if (Object.keys(res.data).length != 0) {
              this.validations.email = true
              this.errorMessage.email = this.$t('auth.emailErrExists')
              this.error = true
              this.sending=false
            }
          })
          .catch(err=>{
            console.log('error: ',err);
          })

        } else {
          this.validations.email = true
          this.errorMessage.email = this.$t('profile.emailError')
          this.error = true
        }
      }
      if (this.email == "") {
        this.validations.email = true
        this.errorMessage.email = this.$t('profile.emailError')
        this.error = true
      }
      if (this.password == "") {
        this.validations.password = true
        this.errorMessage.password = this.$t('profile.passwordError')
        this.error = true
      }
      if (this.password.length < 6) {
        this.validations.password = true
        this.errorMessage.password = this.$t('profile.passwordError')
        this.error = true
      }
      if (this.password != this.confirmPassword) {
        this.validations.confirmPassword = true
        this.errorMessage.confirmPassword = this.$t('auth.passwordErrorMatch')
        this.error = true
      }
      if (!this.confirmPassword||this.confirmPassword=="") {
        this.validations.confirmPassword = true
        this.errorMessage.confirmPassword = this.$t('auth.passwordErrorEmpty')
        this.error = true
      }
      if (!this.terms) {
        this.validations.terms = true
        this.errorMessage.general = "You need to accept our Terms and Conditions to create a profile"
        this.errorMessage.terms="You need to accept this"
        this.error = true
      }
    },
    onSubmit() {
      this.sending=true
      this.validate()
      if (this.error) {
        this.sending=false
        return
      }
      // console.log('submit Running')
      if (this.landlord) {
        this.roleValue = 20
        this.cancelPol = 2
      }
      const userData = {
        userName: this.userName,
        email: this.email,
        password: this.password,
        keepLogged: this.keepLogged,
        roleValue: this.roleValue,
      }
      // console.log(userData)
      axiosAuth.post('/signupNewUser?key='+config.FIREBASE_API_KEY, {
        email: userData.email,
        password: userData.password,
        returnSecureToken: true
      }).then(res => {
        // console.log('get res')
        if (this.$i18n.locale=='zh'){
          AWSSES.emailing(userData, 'ZHSignUp')

        } else {
          if (userData.roleValue>10){
            AWSSES.emailing(userData, 'LLSignUp')
          }
          else {
            AWSSES.emailing(userData, 'TSignUp')
          }
        }
        let dataToSend=res.data
        dataToSend.roleValue=userData.roleValue
        dataToSend.keepLogged=userData.keepLogged
        dataToSend.userName=userData.userName
        dataToSend.dateAdded=new Date()
        if (this.$i18n.locale=='zh'){
          dataToSend.sendChinese=true
        }
        // console.log('Do the signupAction')
        this.signUpAction(dataToSend)
        dataLayer.push({
          'event':'signUpEvent',
        })

      }).catch(err =>{
        this.errorMessage.general =this.$t("auth.emailErrExists")
        this.sending=false
        console.log('error:' ,err)
      })
      campaign.identify({
        "firstname":this.userName,
        "email": this.email
      }, function(err, details) {
        campaign.widgets.load();
      });
    }

  },
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }
}
</script>
<style lang="scss" scoped>
.signup-error {
  color:red;
}
.signup-error label {
    color: red;
}
.signup-error label
.signup-error label {
    border: 1px solid red;
    background-color: #ffc9aa;
}
.my-margin {
  margin-top: 5vh;
  margin-bottom: 10vh;
}
.md-checkbox{
  margin: 5px;
}
.link{
  text-decoration: underline;
  color:#00BFFF
}
</style>
