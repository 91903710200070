<template>
<div id="">
  <Navbar></Navbar>

<v-container>
  <v-layout align-center justify-center >
    <v-flex md6 lg6>

      <form>
        <v-card class="my-margin">
          <v-card-title>
            <div class="title">{{$t('navbar.login')}}</div>
          </v-card-title>
          <v-card-text>
            <div class="">
                <v-text-field :label="$t('tenants.email')" :error-messages="errorMessage.email" type="email" name="email" id="email" autocomplete="email" v-model="email" />
                <v-text-field :label="$t('profile.password')" :error-messages="errorMessage.password" type="password" name="password" id="password" autocomplete="password" v-model="password" />

              <v-checkbox :label="$t('auth.keepLogged')" class='md-layout-item md-size-100 no-padding' v-model="keepLogged"></v-checkbox>

              <div class="forgot-password" @click="forgotPassword=!forgotPassword">
                <span>{{$t('auth.forgotPassword')}}! :O</span>
              </div>

            </div>
            <v-layout justify-space-between  v-if="forgotPassword">
              <v-flex xs12>
                <v-text-field :error-messages="resetEmailErrorMessage" :label="$t('tenants.email')" type="email" name="email" id="resetEmail" autocomplete="email" v-model="resetEmail" />
              </v-flex>
            </v-layout>

          </v-card-text>


          <v-card-actions>
            <v-layout justify-space-between >
              <v-flex class="flex-0">
                <v-btn href='/signup'>{{$t('navbar.signup')}}</v-btn>
              </v-flex>
              <v-flex class="flex-0">
                <v-btn color="primary" v-if="!forgotPassword" :disabled="sending" @keyup.enter="login" @click="logIn">{{$t('navbar.login')}}</v-btn>
                <v-btn flat color="red" v-if="forgotPassword" :disabled="sending"  @click="resetPassword">{{$t('auth.resetPassword')}}</v-btn>
              </v-flex>
            </v-layout>


          </v-card-actions>
        </v-card>
      </form>

    </v-flex>
  </v-layout>
</v-container>




  </div>
</template>
<script>
import {
    mapActions
} from 'vuex'
import config from '../../config'
import * as types from '../../store/types'
import axiosAuth from '../../axios-auth'


export default {
  data: () => ({
    email: "",
    password: "",
    keepLogged: false,
    forgotPassword:false,
    resetEmailErrorMessage:"",
    resetEmail:"",
    validations: {
      email: false,
      password: false,
    },
    errorMessage: "",
    sending: false,
  }),
  methods: {
    ...mapActions({
      logInAction: types.LOG_IN
    }),
    validate() {
      // console.log('validate')
      this.error = false
      this.errorMessage = {}
      this.validations = {
        email: false,
        password: false,
      }
      if (this.email != "") {
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let valid = emailRegex.test(String(this.email))
        if (!valid) {
          this.validations.email = true
          this.errorMessage.email = this.$t("profile.emailError")
          this.error = true
        }
      }
      if (this.email == "") {
        this.validations.email = true
        this.errorMessage.email = this.$t("profile.emailError")
        this.error = true
      }
      if (this.password == "") {
        this.validations.password = true
        this.errorMessage.password = this.$t("auth.noPassword")
        this.error = true
      }
      // console.log('validation done')
    },
    resetPassword() {
      if (this.resetEmail != "") {
        axiosAuth.post('/getOobConfirmationCode?key=' + config.FIREBASE_API_KEY, {
            requestType: "PASSWORD_RESET",
            email: this.resetEmail
          })
          .then(() => {
            // console.log('passwordReset :')
            // console.log(res)
            alert('password reset email has been sent, check your emails!:)')
          })
          .catch(err=>{
            console.log('error: ',err);
          })
      } else {
        this.resetEmailErrorMessage = "Enter a valid email address!"
      }
    },
    logIn() {
      // console.log('Do Login');
      this.sending = true
      this.validate()
      if (this.error) {
        this.sending = false
        return
      }
      // console.log(' TEST loginAction')
      const userData = {
        email: this.email,
        password: this.password,
        keepLogged: this.keepLogged,
      }
      // console.log(userData)
      axiosAuth.post('/verifyPassword?key='+config.FIREBASE_API_KEY, {
        email: userData.email,
        password: userData.password,
        returnSecureToken: true
      }).then(res => {
        // console.log('it worked')
        // console.log(res)
        let dataToSend=res.data
        dataToSend.keepLogged=userData.keepLogged
        this.logInAction(dataToSend)
      }).catch(err => {
        console.log(err)
        // console.log(this.sending + "this is were it stops ")
        this.sending=false
        this.errorMessage.email=this.$t('auth.generalError')
        this.errorMessage.password=this.$t('auth.generalError')
      })


    },
  },
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    // console.log('login page created');

  }
}
</script>
<style lang="scss" scoped>
.error {
    color: white;
}
.error label {
    color: white;
}
.error label .error MdInput {
    border: 1px solid red;
    background-color: #ffc9aa;
}
.my-margin {
    margin-top: 5vh;
    margin-bottom: 10vh;
}
.md-checkbox {
    margin: 5px;
}
.forgot-password {
  cursor: pointer;
}
.no-padding {
  padding-left: 0 !important;
}
</style>
