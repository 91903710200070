<template>
  <div id="">
    <ResetPassword v-if="mode=='resetPassword'"></ResetPassword>
  </div>
</template>
<script>
import ResetPassword from './ResetPassword.vue'
export default {
  name: "",
  data: () => ({
    mode: ""

  }),
  created() {
    //do something after creating vue instance
    this.mode=this.$route.query.mode
  },
  components: {
    ResetPassword,
  }
}
</script>
<style lang="scss" scoped>
</style>
