<template>
  <div id="">
    <v-container grid-list-xs,sm,md,lg,xl>
      <div class="">
        {{$t('auth.changePwd')}}: {{email}}
      </div>
      <v-text-field
        :label="$t('auth.newPassword')"
        v-model="password"
        type="password"
      ></v-text-field>
      <v-text-field
        :label="$t('auth.confirmPassword')"
        v-model="confirmPassword"
        type="password"
        :error-messages="error"
      ></v-text-field>
      <v-btn color="primary" @click="resetPassword">
        {{$t('auth.resetPassword')}}
      </v-btn>
    </v-container>
  </div>
</template>
<script>
import axiosAuth from '../../axios-auth'
import config from '../../config'

export default {
  name: "",
  data: () => ({
    email:"",
    password: "",
    confirmPassword: "",
    error:"",
  }),
  methods: {
    resetPassword() {
      if (this.password === this.confirmPassword) {
        axiosAuth.post('resetPassword?key='+config.FIREBASE_API_KEY, {
          oobCode: this.$route.query.oobCode,
          newPassword: this.password
        })
        .then(() =>{
          alert('password RESET')
        })
        .catch(err=>{
          console.log('error: ',err);
        })

      } else {
        this.error=this.$t('auth.passwordErrorMatch')
      }
    }
  },
  created() {
    //do something after creating vue instance
    // console.log('CREATE')
    // console.log(this.$route.query.oobCode)
      axiosAuth.post('resetPassword?key='+config.FIREBASE_API_KEY, {
          oobCode:this.$route.query.oobCode
      })
      .then(res=>{
        // console.log(res)
          this.email=res.data.email
      })
      .catch(err=>{
        console.log('error: ',err);
      })
  }
}
</script>
<style lang="scss" scoped>
</style>
